import React from "react";
import { Link } from "gatsby";

const BlogCard = ({ article }) => (
  <div className="py-6">
    {article.topics.map((topic, index) => (
      <div
        key={`topic-${article.id}-${index}`}
        className="bg-blue-200 text-blue-800 py-2 px-4 mr-4 font-bold rounded-full inline-block text-sm"
      >
        {topic}
      </div>
    ))}
    <Link to={article.slug}>
      <h2 className="text-2xl my-2 dark:text-primary-200">{article.title}</h2>
      <p className="text-lg text-gray-700 mt-4 dark:text-gray-300">{article.summary}</p>
    </Link>
    <div className="my-2 flex my-4">
      <div>
        <div className="bg-gray-700 rounded-full w-12 h-12"></div>
      </div>
      <div className="px-2">
        <p className="m-0 dark:text-white">{article.author}</p>
        <p className="text-gray-500 m-0 dark:text-gray-400">{article.date}</p>
      </div>
    </div>
  </div>
);

export default BlogCard;
