import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import BlogCard from "../components/BlogCard";
import Title from "../components/Title";

const BlogPage = ({
  path,
  data: {
    allMarkdownRemark: { edges: postEdges },
  },
}) => {
  return (
    <Layout currentPath={path} title="Blog">
      <Title label="Recent articles" />

      <div className="container mx-auto">
        <section className="px-4 flex flex-wrap">
          {postEdges.map((post) => (
            <div className="w-full" key={post.node.frontmatter.slug}>
              <BlogCard article={post.node.frontmatter} />
            </div>
          ))}
        </section>
      </div>
    </Layout>
  );
};

export const BlogQuery = graphql`
  query CorporateBlogQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: "corporate" } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            author
            summary
            topics
          }
        }
      }
    }
  }
`;

export default BlogPage;
